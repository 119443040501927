// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAKcV9EFIoPmCLVa_yPulxitGFZiNBXsQY",
    authDomain: "livemachineofficial.firebaseapp.com",
    databaseURL: "https://livemachineofficial.firebaseio.com",
    projectId: "livemachineofficial",
    storageBucket: "livemachineofficial.appspot.com",
    messagingSenderId: "838677389339",
    appId: "1:838677389339:web:5bdcefcb8c8c731ede18be",
    measurementId: "G-3MKGTVXNWJ"
  },
  mjFirebase: {
    apiKey: "AIzaSyAftY28t8aXsx4JBNAJskNVULNza8ToTj4",
    authDomain: "morejungle.firebaseapp.com",
    databaseURL: "https://morejungle.firebaseio.com",
    projectId: "morejungle",
    storageBucket: "morejungle.appspot.com",
    messagingSenderId: "614144281012",
    appId: "1:614144281012:web:acb1fda6ddf705ee"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
