import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { SseService } from '../services/sse.service';

@Component({
  selector: 'app-livereactions',
  templateUrl: './livereactions.component.html',
  styleUrls: ['./livereactions.component.scss']
})
export class LivereactionsComponent implements OnInit {

  public page: any;
  public reactions = {
    NONE: 0,
    LIKE: 0,
    LOVE: 0,
    WOW: 0,
    HAHA: 0,
    SAD: 0,
    ANGRY: 0,
    THANKFUL: 0,
    PRIDE: 0,
    WTF: 0
  };
  constructor(private route: ActivatedRoute, firestore: AngularFirestore, private sseService: SseService) {
    var token = "EAAD1ZBDOeMj4BAMueqybk0X0RqfefbZAkCDNII6Pe72FGiwU17YxhRo4iFGLHtnlHK0bru2ZCSbiLZC0oMZA6tbZBqIzG0w2aTFSXRW9woEI6sdPSJGyGXQaAUk1JpsTP63eZAjmSBOjL0ngDP7cKgBjXnslLzWY4q2qaqr1jMNywZDZD";
    this.route.params.subscribe(params => {
      const id = params['id'];
      // firestore.collection('facebookpages').doc(id).valueChanges().subscribe(x => this.page = x);
      let url = 'https://graph.facebook.com/15087023444/posts?fields=likes.limit(0).summary(true),comments.limit(0).summary(true),type,message,created_time,link,name,caption,description,attachments{subattachments,media,type}&limit=10';

      // this.http
      //     .get(url, requestOptions)
      //     .map(response => response.json())
      this.sseService
        .getServerSentEvent("https://streaming-graph.facebook.com/" + id + "/live_reactions?access_token=" + token + "&fields=reaction_stream")
        .subscribe(sse => {
          var stream = JSON.parse(sse.data).reaction_stream;
          var that = this;
          stream.forEach(function (reaction) {
            //NONE, LIKE, LOVE, WOW, HAHA, SAD, ANGRY, THANKFUL, and PRIDE.
            switch (reaction.key) {
              case "NONE":
                that.reactions.NONE = reaction.value;
                break;
              case "LIKE":
                that.reactions.LIKE = reaction.value;
                break;
              case "LOVE":
                that.reactions.LOVE = reaction.value;
                break;
              case "WOW":
                that.reactions.WOW = reaction.value;
                break;
              case "HAHA":
                that.reactions.HAHA = reaction.value;
                break;
              case "SAD":
                that.reactions.SAD = reaction.value;
                break;
              case "ANGRY":
                that.reactions.ANGRY = reaction.value;
                break;
              case "THANKFUL":
                that.reactions.THANKFUL = reaction.value;
                break;
              case "PRIDE":
                that.reactions.PRIDE = reaction.value;
                break;
              default:
                that.reactions.WTF = reaction.value;
            }
          });

          console.log(this.reactions)
        });
    });
  }

  ngOnInit() {
  }

}
