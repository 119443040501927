import { Component, OnInit } from '@angular/core';
import liff from '@line/liff';

type UnPromise<T> = T extends Promise<infer X>? X : T;

@Component({
  selector: 'app-liff',
  templateUrl: './liff.component.html',
  styleUrls: ['./liff.component.scss']
})
export class LiffComponent implements OnInit {
  os: ReturnType<typeof liff.getOS>;  
  profile: UnPromise<ReturnType<typeof liff.getProfile>>;
  constructor() { }
  ngOnInit() {
    liff.init({liffId:'1620631574-xbOWbggr'}).then(()=>{
      this.os=liff.getOS();
      if(liff.isLoggedIn()){
        liff.getProfile().then( profile =>{
          this.profile = profile;
          console.log(profile);
        }).catch(console.error);
      }else{
        //liff.login()
      }
    }).catch(console.error);
  }

}
