import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Audience } from '../animations/audience';
import { Theater } from '../animations/theater';
import { Stadium } from '../animations/stadium';
import { Auction } from '../animations/auction';
import Phaser from 'phaser';
import { MainScene, BootGame } from '../animations/main-scene';
import { AngularfirestoreLiveMachineService, AngularfirestoreMJStoreService } from '../services/afs-ext';
@Component({
  selector: 'app-crowd',
  templateUrl: './crowd.component.html',
  styleUrls: ['./crowd.component.scss']
})
export class CrowdComponent implements OnInit {
  @ViewChild("myCanvas", {static: true}) canvasRef: ElementRef;

  phaserGame: Phaser.Game;
  config: Phaser.Types.Core.GameConfig;
  
  private ctx: CanvasRenderingContext2D;
  public page: any;
  public users: any[]
  public db: AngularfirestoreLiveMachineService;
  public db2: AngularfirestoreMJStoreService;
  public post_id: string;
  constructor(private route: ActivatedRoute, db: AngularfirestoreLiveMachineService, db2: AngularfirestoreMJStoreService) { 
    this.route.params.subscribe(params => {
      this.post_id = params['id'];
      this.db = db;
      this.db2 = db2;
    });
  }

  ngOnInit() {
    this.ctx = this.canvasRef.nativeElement.getContext('2d');
    //const flowers = new Audience(this.canvasRef.nativeElement, this.db, this.pageId);
    //const theater = new Theater(this.canvasRef.nativeElement, this.db, this.pageId)
    const stadium = new Auction(this.canvasRef.nativeElement, this.db, this.db2, this.post_id)
    // var x = 0;
    // setInterval(() => {
    //   this.ctx.clearRect(0, 0, 1000, 1000);
    //   this.drawCircle(x % 1000);
    //   this.drawBorder(x % 1000);
    //   x++;
    // }, 1);
  }

  drawCircle(x) {
    this.ctx.beginPath();
    this.ctx.arc(x, 100, 10, 0, 2 * Math.PI);
    this.ctx.fillStyle = "red";
    this.ctx.fill();
  }

  drawBorder(x) {
    this.ctx.beginPath();
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(1000, 0);
    this.ctx.lineTo(1000, 1000);
    this.ctx.lineTo(0, 1000);
    this.ctx.lineTo(0, 0);
    this.ctx.stroke();
  }
}
