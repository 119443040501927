import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-facebookcomments',
  templateUrl: './facebookcomments.component.html',
  styleUrls: ['./facebookcomments.component.scss']
})
export class FacebookcommentsComponent implements OnInit {
  public page: any;
  constructor(private route: ActivatedRoute, firestore: AngularFirestore) { 
    this.route.params.subscribe(params => {
      const id = params['id'];
      firestore.collection('facebookpages').doc(id).valueChanges().subscribe(x => this.page = x);
    });
  }

  ngOnInit() {
  }

}
