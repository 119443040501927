import { BrowserModule } from '@angular/platform-browser';
import { NgModule, PLATFORM_ID, NgZone } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FacebookpagesComponent } from './facebookpages/facebookpages.component';
import { HomeComponent } from './home/home.component';
import { FacebookcommentsComponent } from './facebookcomments/facebookcomments.component';
import { LivereactionsComponent } from './livereactions/livereactions.component';
import { CounterDirective } from './counter.directive';
import { CountdownComponent } from './countdown/countdown.component';
import { LivecommentsComponent } from './livecomments/livecomments.component';
import { CrowdComponent } from './crowd/crowd.component';
import { AngularfirestoreLiveMachineService, AngularfirestoreMJStoreService, AngularfirestoreLiveMachineStoreFactory, AngularfirestoreMJStoreFactory } from './services/afs-ext';
import { LiffComponent } from './liff/liff.component';

@NgModule({
  declarations: [
    AppComponent,
    FacebookpagesComponent,
    HomeComponent,
    FacebookcommentsComponent,
    LivereactionsComponent,
    CounterDirective,
    CountdownComponent,
    LivecommentsComponent,
    CrowdComponent,
    LiffComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: AngularfirestoreLiveMachineService, deps: [PLATFORM_ID, NgZone],
      useFactory: AngularfirestoreLiveMachineStoreFactory
    },
    {
      provide: AngularfirestoreMJStoreService, deps: [PLATFORM_ID, NgZone],
      useFactory: AngularfirestoreMJStoreFactory
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
