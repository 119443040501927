import { AngularFirestore } from '@angular/fire/firestore';

import * as spriter from '../animations/spriter';
import * as atlas from '../animations/atlas';
import { RenderCtx2D } from '../animations/render-ctx2d';
import { Auction } from '../animations/auction';
import { firestore } from 'firebase/app';

export class Bidder {
  context: CanvasRenderingContext2D;
  pose: spriter.Pose;
  anim_data: spriter.Data;
  atlas_data: atlas.Data;
  render_ctx2d: RenderCtx2D;
  x = 500
  y = 800
  width = 1920;
  height = 1080;
  public angle = 0;
  public radiusX = this.width / 2;
  public radiusY = 50;
  public centerX = 900;
  public centerY = 900;
  public moshCenterX = this.width / 2 - 100;
  public moshCenterY = 800;
  public moshradiusX = this.width / 2 - 100
  xx
  yy
  xxx
  yyy
  xxxx
  yyyy
  bidding: boolean = false;
  path: string
  user: any;
  seat: number;
  idleKey: string;
  attackKey: string;
  walkKey: string;
  sleepKey: string;
  jumpKey: string;
  walkSpeed: number = 10;
  auction: Auction;
  db: AngularFirestore
  bomb: Bidder;
  constructor(auction: Auction, player: any, atlas_data: any, path: string, user: any, seat: number, db: AngularFirestore) {
    this.user = user;
    if (path != 'Bomb') {
      this.bomb = new Bidder(auction, auction.bomb, auction.bombAtlas, 'Bomb', { displayName: this.user.displayName }, -1, db);
    }
    this.db = db;
    this.auction = auction
    this.path = path
    this.seat = seat
    this.context = auction.context;
    this.render_ctx2d = new RenderCtx2D(this.context);
    this.anim_data = new spriter.Data().load(player);
    this.atlas_data = new atlas.Data().importTpsJson(atlas_data);
    var dir_path = 'assets/animations/' + path;
    const images: { [key: string]: HTMLImageElement } = {};
    this.render_ctx2d.loadData(this.anim_data, this.atlas_data, images);
    this.atlas_data.pages.forEach((page: atlas.Page) => {
      const image_key: string = page.name;
      const image_url: string = dir_path + "/" + image_key;
      //counter_inc();
      const image: HTMLImageElement = images[image_key] = this.loadImage(image_url, ((page: atlas.Page) => {
        return function (err: string, image: HTMLImageElement): void {
          if (err) {
            console.log("error loading:", image && image.src || page.name);
          }
          page.w = page.w || image.width;
          page.h = page.h || image.height;
          //counter_dec();
        };
      })(page));
    });
    this.pose = new spriter.Pose(this.anim_data); // create Spriter pose and attach data
    var ekey = this.pose.data.entity_keys[0];
    var animkeys = this.pose.data.entity_map[ekey].animation_keys;

    this.idleKey = animkeys.find((x: string) => {
      return x.toLowerCase().indexOf('idle') >= 0
    })
    if (!this.idleKey) {
      this.idleKey = animkeys[1]
    }
    this.attackKey = animkeys.find((x: string) => {
      return x.toLowerCase().indexOf('attack2') >= 0
    })
    if (!this.attackKey) {
      this.attackKey = animkeys.find((x: string) => {
        return x.toLowerCase().indexOf('attack') >= 0
      })
    }
    if (!this.attackKey) {
      this.attackKey = animkeys.find((x: string) => {
        return x.toLowerCase().indexOf('slash') >= 0
      })
    }
    if (!this.attackKey) {
      this.attackKey = this.idleKey;
    }

    this.walkKey = animkeys.find((x: string) => {
      return x.toLowerCase().indexOf('walk') >= 0
    })
    if (!this.walkKey) {
      this.walkKey = this.idleKey;
    }

    this.jumpKey = animkeys.find((x: string) => {
      return x.toLowerCase().indexOf('jump') >= 0
    })
    if (!this.jumpKey) {
      this.jumpKey = this.idleKey;
    }

    this.sleepKey = animkeys.find((x: string) => {
      return x.toLowerCase().indexOf('die') >= 0
    })
    if (!this.sleepKey) {
      this.sleepKey = animkeys.find((x: string) => {
        return x.toLowerCase().indexOf('dyi') >= 0
      })
    }
    if (!this.sleepKey) {
      this.sleepKey = animkeys.find((x: string) => {
        return x.toLowerCase().indexOf('dea') >= 0
      })
    }
    if (!this.sleepKey) {
      this.sleepKey = animkeys.find((x: string) => {
        return x.toLowerCase().indexOf('smoke') >= 0
      })
    }
    if (!this.sleepKey) {
      this.sleepKey = this.idleKey;
    }
    // this.idleKey = this.sleepKey
    // this.walkKey = this.sleepKey
    // this.walkKey = this.sleepKey
    this.pose.setEntity(ekey); // set entity by name
    this.updateAnim(this.idleKey); // set animation by name
  }
  fired = false;
  is_boss = false;
  updateAnim(newKey) {
    if (this.pose.anim_key != newKey) {
      this.pose.setAnim(newKey)
    }
  }
  public draw() {
    if (this.user.state == 'moshpit') {
      this.updateAnim(this.walkKey); // set animation by name
      var dx = Math.sin(this.angle + this.xx / 10) * this.radiusX;
      var dy = Math.cos(this.angle) * this.radiusY;
      this.x = this.centerX + dx;
      this.y = this.centerY + dy;
      //this.flip = (dy < 0);
      this.angle += this.walkSpeed / 600;
      this.hit = false;
    } else if (this.user.state == 'jump') {
      this.updateAnim(this.idleKey); // set animation by name
      this.x = this.xx;
      this.y = this.yy + Math.cos(this.angle) * this.radiusY - 50;
      this.angle += 6 * this.walkSpeed / 400;
      this.hit = false;
    } else if (this.user.state == 'win') {
      this.updateAnim(this.idleKey); // set animation by name
      this.x = this.xxxx;
      this.y = this.yyyy + Math.cos(this.angle) * this.radiusY - 50;
      this.angle += 6 * this.walkSpeed / 400;
      this.hit = false;
    } else if (this.user.state == 'bodysurf') {
      this.updateAnim(this.sleepKey); // set animation by name
      this.y = this.moshCenterY;
      this.x = this.moshCenterX / 2 + Math.cos(this.angle) * this.moshradiusX / 2;
      this.angle += this.walkSpeed / 400;
      this.hit = false;
    } else if (this.user.state == 'sleep') {
      this.updateAnim(this.sleepKey);
      //this.x = this.xx;
      //this.y = this.yy;
      this.hit = false;
    }
    else if (this.is_boss) {
      this.x = this.xx + Math.cos(this.angle) * this.radiusX;
      this.angle += 20 / 1000;
    } else if (this.user.state == 'attack' && this.auction.megaBoss) {
      if (this.user.points > 0) {
        this.updateAnim(this.attackKey);
        this.x = this.xx;
        this.y = this.yy;
        if (!this.fired && !this.hit) {
          this.fired = true;
          this.missileX = this.x;
          this.missileY = this.y;
        }
        this.checkMissile()
      } else {
        this.db.doc('lineusers/' + this.user.lineId).set({ state: 'sleep' }, { merge: true }).then(() => {
          this.user.state = 'sleep';
        })
      }
    }
    else if (this.user.state == 'Blast') {
      this.updateAnim('Blast');
    }
    else if (this.user.state == 'support') {
      // if(!this.x){
      //   this.x = this.xx
      // }
      // if(!this.y){
      //   this.y = this.yy
      // }
      this.updateAnim(this.walkKey);
      // this.x = this.xxx;
      // this.y = this.yyy;
      this.walkTo(this.xxx, this.yyy)
    }
    else {
      this.updateAnim(this.idleKey);
      this.x = this.xx;
      this.y = this.yy;
      //this.setAction('Idle');
      this.hit = false;
    }

    if (this.user.points <= 0) {
      this.user.points = 0
      if (this.user.state != 'sleep') {
        this.db.doc('lineusers/' + this.user.lineId).set({ state: 'sleep' }, { merge: true }).then(() => {

        })
        this.user.state = 'sleep';
      }
    }

    if ((this.user.state == 'sleep' || this.user.state == 'bodysurf') && this.pose.time >= this.pose.curAnim().max_time - 20) {
      if (this.is_boss) {
        return;
      }
    } else {
      this.pose.update(1000 / 60); // accumulate time
      this.pose.strike(); // process time slice   
    }

    this.context.save()
    this.context.translate(this.x, this.y);

    if (this.path == 'MegaBoss') {
      this.context.scale(0.65, -0.65);
    } else if (this.path == 'Boss1') {
      this.context.scale(0.25, -0.25);
    } else if (this.path == 'Bomb') {
      this.context.scale(1, -1);
    } else {
      this.context.scale(0.35, -0.35);
    }
    // if (this.user.state == 'bodysurf') {
    //   this.context.translate(this.width/2, this.height/2)
    //   this.context.rotate(Math.PI/2)
    // }

    this.render_ctx2d.drawPose(this.pose, this.atlas_data);

    // if (this.user.state == 'bodysurf') {
    //   this.context.rotate(-Math.PI/2)
    //   this.context.translate(-this.width/2, -this.height/2)
    // }

    this.context.restore()
    this.context.textAlign = "center";
    this.context.fillStyle = "white";
    this.context.font = '50px sukhumvit';

    // this.context.fillText('จะว่าไปแล้ว โคมไฟของกู', this.x + 25, this.y - 300)
    this.context.font = '48px sukhumvit';
    this.context.textAlign = "center";
    this.context.fillStyle = "white";
    if ((Date.now() / 1000 | 0) - this.user.lastCommentTime < 30) {
      this.context.fillText(this.user.lastCommentMessage, this.x + 25, this.y - 200)
    }

    this.context.font = 'bold 40px sukhumvit';
    var name = this.user.displayName ? this.user.displayName : '?';
    if (this.is_boss) {
      this.context.font = 'bold 100px sukhumvit';
      name = this.HP > 0 ? this.HP : 'เอื้ออออออ';
      //name = Math.floor(this.pose.time);
      if (this.path == 'Boss1') {
        if (this.pose.time > 100 && this.pose.time < 900) {
          for (let chindex = 0; chindex < this.auction.bidders.length; chindex++) {
            const bidder = this.auction.bidders[chindex];
            var dx = this.x - bidder.x;
            if (Math.abs(dx) < 20) {
              bidder.user.points -= Math.floor(10 / Math.abs(dx))
              if (bidder.user.points >= 0) {
                // this.db.doc('lineusers/' + bidder.user.lineId).set({ points: bidder.user.points }, { merge: true }).then(() => {
                // })
              }
            }
          }
        }
      }
      if (this.path == 'MegaBoss') {
        if (this.pose.time > 500 && this.pose.time < 1100) {
          for (let chindex = 0; chindex < this.auction.bidders.length; chindex++) {
            const bidder = this.auction.bidders[chindex];
            var dx = this.x - bidder.x;
            if (Math.abs(dx) < 50) {
              bidder.user.points -= Math.floor(30 / Math.abs(dx))
              if (bidder.user.points >= 0) {
                // this.db.doc('lineusers/' + bidder.user.lineId).set({ points: bidder.user.points }, { merge: true }).then(() => {
                // })
              }
            }
          }
        }
      }
    }
    this.context.fillText(name, this.x, this.y - 120)

    if (this.fired && this.auction.megaBoss && this.auction.megaBoss.HP > 0) {
      this.bomb.xx = this.missileX;
      this.bomb.yy = this.missileY;
      this.bomb.draw()
    }
    if (this.is_boss && this.bomb.user.state == 'Blast') {
      this.bomb.draw()
    }
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  missileX
  missileY
  hit = false
  HP = 300
  checkMissile() {
    if (!this.auction.megaBoss || this.auction.megaBoss.HP <= 0) {
      this.fired = false;
      this.db.doc('lineusers/' + this.user.lineId).set({ state: 'still' }, { merge: true }).then(() => {
        this.user.state = 'still';
      })
    }
    var easing = 1 / 50;
    var dx = this.auction.megaBoss.x - this.missileX;
    if (Math.abs(dx) < 50) {
      this.missileX = this.auction.megaBoss.x;
    } else {
      var vx = dx * easing;
      this.missileX += vx;
    }

    var dy = this.auction.megaBoss.y - this.missileY;
    if (Math.abs(dy) < 50) {
      this.missileY = this.auction.megaBoss.y;
    } else {
      var vy = dy * easing;
      this.missileY += vy;
    }
    if (Math.abs(dx) < 60 && Math.abs(dy) < 60) {
      this.bomb.user.state = 'Blast'
      this.auction.megaBoss.bomb.user.state = 'Blast'
      this.auction.megaBoss.bomb.x = this.auction.megaBoss.x;
      this.auction.megaBoss.bomb.y = this.auction.megaBoss.y;
      this.auction.megaBoss.bomb.draw();
    }
    if (this.fired && Math.abs(dx) < 40 && Math.abs(dy) < 40) {
      this.fired = false;
      this.bomb.user.state = 'still'
      var hitPointDecrease = this.randomIntFromInterval(Math.floor(this.auction.megaBoss.HP * 0.2), Math.floor(this.auction.megaBoss.HP * 0.5));
      hitPointDecrease += this.user.points | 10
      this.auction.megaBoss.HP -= hitPointDecrease;
      this.auction.megaBoss.bomb.user.state = 'still'
      this.auction.megaBoss.bomb.x = -500;
      this.auction.megaBoss.bomb.y = -500;
      if (this.auction.megaBoss.HP <= 0) {
        this.auction.megaBoss.HP = 0;
        this.auction.megaBoss.user.state = 'sleep';
        for (let chindex = 0; chindex < this.auction.bidders.length; chindex++) {
          const bidder = this.auction.bidders[chindex];
          this.db.doc('lineusers/' + bidder.user.lineId).set({ points: bidder.user.points }, { merge: true }).then(() => {
          })
        }
      }
      this.db.doc('lineusers/' + this.user.lineId).set({ state: 'still', points: this.user.points + 3 }, { merge: true }).then(() => {
        this.user.state = 'still';
      })
    }
  }

  walkTo(targetX, targetY) {
    var easing = 1 / 50;
    var dx = targetX - this.x;
    if (Math.abs(dx) < 40) {
      this.x = targetX;
    } else {
      var vx = dx * easing;
      this.x += vx;
    }

    var dy = targetY - this.y;
    if (Math.abs(dy) < 40) {
      this.y = targetY;
    } else {
      var vy = dy * easing;
      this.y += vy;
    }
    if (Math.abs(dx) < 50 && Math.abs(dy) < 50) {
      this.updateAnim(this.idleKey)
      // this.db.doc('lineusers/' + this.user.lineId).set({ state: 'still', points: this.user.points + 3 }, { merge: true }).then(() => {
      //   this.user.state = 'still';
      // })
    }
  }

  loadImage(url: string, callback: (error: string, image: HTMLImageElement) => void): HTMLImageElement {
    const image: HTMLImageElement = new Image();
    image.crossOrigin = "Anonymous";
    image.addEventListener('error', function (): void { callback("error", null); });
    image.addEventListener('abort', function (): void { callback("abort", null); });
    image.addEventListener('load', function (): void { callback(null, image); });
    image.src = url;
    return image;
  }
}