import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FacebookpagesComponent } from './facebookpages/facebookpages.component';
import { HomeComponent } from './home/home.component';
import { FacebookcommentsComponent } from './facebookcomments/facebookcomments.component';
import { LivereactionsComponent } from './livereactions/livereactions.component';
import { CountdownComponent } from './countdown/countdown.component';
import { LivecommentsComponent } from './livecomments/livecomments.component';
import { CrowdComponent } from './crowd/crowd.component';
import { LiffComponent } from './liff/liff.component';


const routes: Routes = [
  {
    path: 'facebookpages/:id',
    component: FacebookpagesComponent
  },
  {
    path: 'facebookcomments/:id',
    component: FacebookcommentsComponent
  },
  {
    path: 'livecomments/:id',
    component: LivecommentsComponent
  },
  {
    path: 'crowd/:id',
    component: CrowdComponent
  },
  {
    path: 'liff',
    component: LiffComponent
  },
  {
    path: 'livereactions/:id',
    component: LivereactionsComponent
  },
  {
    path: 'countdown/:from',
    component: CountdownComponent
  },
  {
    path: '',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
