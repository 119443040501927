
import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';

// @Injectable({
//   providedIn: 'root'
// })

@Injectable()
export class AngularfirestoreLiveMachineService extends AngularFirestore {}

export function AngularfirestoreLiveMachineStoreFactory(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebase, 'livemachine', false, null, platformId, zone, null);
}

@Injectable()
export class AngularfirestoreMJStoreService extends AngularFirestore {}

export function AngularfirestoreMJStoreFactory(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.mjFirebase, 'morejungle', false, null, platformId, zone, null);
}