import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {

  public from: number;
  constructor(private route: ActivatedRoute, firestore: AngularFirestore) { 
    this.route.params.subscribe(params => {
      this.from = parseInt(params['from']);
    });
  }
  ngOnInit() {
  }

}
