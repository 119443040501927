import { AngularfirestoreLiveMachineService, AngularfirestoreMJStoreService } from '../services/afs-ext';
import { Bidder } from '../models/bidder';
import { environment } from '../../environments/environment';

import p1 from '../../assets/animations/Golem_1/Animations.json';
import a1 from '../../assets/animations/Golem_1/Animations.tps.json';

import p2 from '../../assets/animations/Golem_2/Animations.json';
import a2 from '../../assets/animations/Golem_2/Animations.tps.json';

import p3 from '../../assets/animations/Golem_3/Animations.json';
import a3 from '../../assets/animations/Golem_3/Animations.tps.json';

import p4 from '../../assets/animations/Golem_01/Animations.json';
import a4 from '../../assets/animations/Golem_01/Animations.tps.json';

import p5 from '../../assets/animations/Golem_02/Animations.json';
import a5 from '../../assets/animations/Golem_02/Animations.tps.json';

import p6 from '../../assets/animations/Golem_03/Animations.json';
import a6 from '../../assets/animations/Golem_03/Animations.tps.json';

import p7 from '../../assets/animations/Satyr_01/Animations.json';
import a7 from '../../assets/animations/Satyr_01/Animations.tps.json';

import p8 from '../../assets/animations/Satyr_02/Animations.json';
import a8 from '../../assets/animations/Satyr_02/Animations.tps.json';

import p9 from '../../assets/animations/Satyr_03/Animations.json';
import a9 from '../../assets/animations/Satyr_03/Animations.tps.json';

import p10 from '../../assets/animations/Reaper_Man_1/Animations.json';
import a10 from '../../assets/animations/Reaper_Man_1/Animations.tps.json';

import p11 from '../../assets/animations/Reaper_Man_2/Animations.json';
import a11 from '../../assets/animations/Reaper_Man_2/Animations.tps.json';

import p12 from '../../assets/animations/Reaper_Man_3/Animations.json';
import a12 from '../../assets/animations/Reaper_Man_3/Animations.tps.json';


import p13 from '../../assets/animations/1_TROLL/Animations.json';
import a13 from '../../assets/animations/1_TROLL/Animations.tps.json';

import p14 from '../../assets/animations/2_TROLL/Animations.json';
import a14 from '../../assets/animations/2_TROLL/Animations.tps.json';

import p15 from '../../assets/animations/3_TROLL/Animations.json';
import a15 from '../../assets/animations/3_TROLL/Animations.tps.json';


import p16 from '../../assets/animations/Fallen_Angels_1/Animations.json';
import a16 from '../../assets/animations/Fallen_Angels_1/Animations.tps.json';

import p17 from '../../assets/animations/Fallen_Angels_2/Animations.json';
import a17 from '../../assets/animations/Fallen_Angels_2/Animations.tps.json';

import p18 from '../../assets/animations/Fallen_Angels_3/Animations.json';
import a18 from '../../assets/animations/Fallen_Angels_3/Animations.tps.json';

import p19 from '../../assets/animations/Goblin/Animations.json';
import a19 from '../../assets/animations/Goblin/Animations.tps.json';

import p20 from '../../assets/animations/Orge/Animations.json';
import a20 from '../../assets/animations/Orge/Animations.tps.json';

import p21 from '../../assets/animations/Orc/Animations.json';
import a21 from '../../assets/animations/Orc/Animations.tps.json';

import p22 from '../../assets/animations/boss_4/Animations.json';
import a22 from '../../assets/animations/boss_4/Animations.tps.json';

import p23 from '../../assets/animations/boss_3/Animations.json';
import a23 from '../../assets/animations/boss_3/Animations.tps.json';

import p24 from '../../assets/animations/boss_2/Animations.json';
import a24 from '../../assets/animations/boss_2/Animations.tps.json';

import p25 from '../../assets/animations/MegaBoss/Animations.json';
import a25 from '../../assets/animations/MegaBoss/Animations.tps.json';

import p26 from '../../assets/animations/Boss1/Animations.json';
import a26 from '../../assets/animations/Boss1/Animations.tps.json';

import p27 from '../../assets/animations/M04/Animations.json';
import a27 from '../../assets/animations/M04/Animations.tps.json';

import p28 from '../../assets/animations/M05/Animations.json';
import a28 from '../../assets/animations/M05/Animations.tps.json';

import p29 from '../../assets/animations/M06/Animations.json';
import a29 from '../../assets/animations/M06/Animations.tps.json';

import p30 from '../../assets/animations/1_MUMMY/Animations.json';
import a30 from '../../assets/animations/1_MUMMY/Animations.tps.json';

import p31 from '../../assets/animations/2_MUMMY/Animations.json';
import a31 from '../../assets/animations/2_MUMMY/Animations.tps.json';

import p32 from '../../assets/animations/3_MUMMY/Animations.json';
import a32 from '../../assets/animations/3_MUMMY/Animations.tps.json';

import p33 from '../../assets/animations/Bee/Animations.json';
import a33 from '../../assets/animations/Bee/Animations.tps.json';

import p34 from '../../assets/animations/Bird/Animations.json';
import a34 from '../../assets/animations/Bird/Animations.tps.json';

import p35 from '../../assets/animations/Crab/Animations.json';
import a35 from '../../assets/animations/Crab/Animations.tps.json';

import p36 from '../../assets/animations/Evil Bald Guy/Animations.json';
import a36 from '../../assets/animations/Evil Bald Guy/Animations.tps.json';

import p37 from '../../assets/animations/Monk Guy/Animations.json';
import a37 from '../../assets/animations/Monk Guy/Animations.tps.json';

import p38 from '../../assets/animations/Old Guy/Animations.json';
import a38 from '../../assets/animations/Old Guy/Animations.tps.json';

import p39 from '../../assets/animations/Magician_1/Animations.json';
import a39 from '../../assets/animations/Magician_1/Animations.tps.json';

import p40 from '../../assets/animations/Magician_2/Animations.json';
import a40 from '../../assets/animations/Magician_2/Animations.tps.json';

import p41 from '../../assets/animations/Magician_3/Animations.json';
import a41 from '../../assets/animations/Magician_3/Animations.tps.json';

import p42 from '../../assets/animations/Skeleton/Animations.json';
import a42 from '../../assets/animations/Skeleton/Animations.tps.json';

import p43 from '../../assets/animations/Zombie/Animations.json';
import a43 from '../../assets/animations/Zombie/Animations.tps.json';

import p44 from '../../assets/animations/Death_Knight/Animations.json';
import a44 from '../../assets/animations/Death_Knight/Animations.tps.json';

import p45 from '../../assets/animations/Skull Knight/Animations.json';
import a45 from '../../assets/animations/Skull Knight/Animations.tps.json';

import p46 from '../../assets/animations/Pumpkin Head Guy/Animations.json';
import a46 from '../../assets/animations/Pumpkin Head Guy/Animations.tps.json';

import p47 from '../../assets/animations/Vampire/Animations.json';
import a47 from '../../assets/animations/Vampire/Animations.tps.json';

import p48 from '../../assets/animations/Greek Warrior/Animations.json';
import a48 from '../../assets/animations/Greek Warrior/Animations.tps.json';

import p49 from '../../assets/animations/Spartan Knight with Spear/Animations.json';
import a49 from '../../assets/animations/Spartan Knight with Spear/Animations.tps.json';

import p50 from '../../assets/animations/Frontier Defender Spartan Knight/Animations.json';
import a50 from '../../assets/animations/Frontier Defender Spartan Knight/Animations.tps.json';

import p51 from '../../assets/animations/Joy/Animations.json';
import a51 from '../../assets/animations/Joy/Animations.tps.json';

import p52 from '../../assets/animations/Dum/Animations.json';
import a52 from '../../assets/animations/Dum/Animations.tps.json';

import p53 from '../../assets/animations/Net/Animations.json';
import a53 from '../../assets/animations/Net/Animations.tps.json';

import p54 from '../../assets/animations/Sket/Animations.json';
import a54 from '../../assets/animations/Sket/Animations.tps.json';

import p55 from '../../assets/animations/Nic/Animations.json';
import a55 from '../../assets/animations/Nic/Animations.tps.json';

import bomb from '../../assets/animations/Bomb/Animations.json';
import bombAtlas from '../../assets/animations/Bomb/Animations.tps.json';


// import {
//     // main APIs
//     Client,
//     //middleware,

//     // exceptions
//     //JSONParseError,
//     //SignatureValidationFailed,

//     // types
//     //TemplateMessage,
//     MessageEvent,
//     TextMessage,
//     WebhookEvent,
//     FollowEvent,
//     UnfollowEvent,
//     PostbackEvent,
//     ImageEventMessage,
//     TextEventMessage,
//     FlexMessage,
// } from "@line/bot-sdk";
// const client = new Client({
//     channelAccessToken: 'lXyF1fbRMsETONQGbo8Sfryx5NR7dpYZmahPzuP+q4zvpDUbkG132BX8uNNECJ4fS1cH1UJRQ2dcQQs2ft5yLZuf3dt91mjuNj1xbh56YtCnZmUz9kM26JZuoagIoCz3WI08tawGaXRWN6RzYTK1vAdB04t89/1O/w1cDnyilFU='
// });


import * as firebase from 'firebase';
export class Auction {
    bidding: any = { bidding: false }
    game: string = ''
    stock: number = 0;
    winners = []
    bomb = bomb;
    bombAtlas = bombAtlas;
    context: CanvasRenderingContext2D;
    bidders: Bidder[] = []
    comments: any[] = []
    players = [
        //p26, p25,
        //p52, p51, p53, p54, p55,
        //p30, p31, p32,
        p48, p49, p50,
        // p45, p46, p47,
        // p42, p43, p44,
        p39, p40, p41,
        p36, p37, p38,
        p33, p34, p35,
        p27, p28, p29,
        p22, p23, p24,
        p1, p2, p3,
        p4, p5, p6,
        p7, p8, p9,
        p10, p11, p12,
        p13, p14, p15,
        p16, p17, p18,
        p19, p20, p21
    ]
    atlases = [
        //a26, a25,
        //a52, a51, a53, a54, a55,
        //a30, a31, a32,
        a48, a49, a50,
        // a45, a46, a47,
        // a42, a43, a44,
        a39, a40, a41,
        a36, a37, a38,
        a33, a34, a35,
        a27, a28, a29,
        a22, a23, a24,
        a1, a2, a3,
        a4, a5, a6,
        a7, a8, a9,
        a10, a11, a12,
        a13, a14, a15,
        a16, a17, a18,
        a19, a20, a21,
    ]
    paths = [
        //'Boss1', 'MegaBoss',
        //'Dum', 'Joy', 'Net', 'Sket', 'Nic',
        //'1_MUMMY', '2_MUMMY', '3_MUMMY',
        'Greek Warrior', 'Spartan Knight with Spear', 'Frontier Defender Spartan Knight',
        // 'Skull Knight', 'Pumpkin Head Guy', 'Vampire',
        // 'Skeleton', 'Zombie', 'Death_Knight',
        'Magician_1', 'Magician_2', 'Magician_3',
        'Evil Bald Guy', 'Monk Guy', 'Old Guy',
        'Bee', 'Bird', 'Crab',
        'M04', 'M05', 'M06',
        'boss_4', 'boss_3', 'boss_2',
        'Golem_1', 'Golem_2', 'Golem_3',
        'Golem_01', 'Golem_02', 'Golem_03',
        'Satyr_01', 'Satyr_02', 'Satyr_03',
        'Reaper_Man_1', 'Reaper_Man_2', 'Reaper_Man_3',
        '1_TROLL', '2_TROLL', '3_TROLL',
        'Fallen_Angels_1', 'Fallen_Angels_2', 'Fallen_Angels_3',
        'Goblin', 'Orge', 'Orc',
    ]
    rowsCount = 5;
    colsCount = 6;
    seatWidth = 300;
    seatHeight = 200;
    offsetX = 60;
    offsetY = 15;
    seats = [
        -1, -1, 0, 0, 0, 0, //6
        -1, -1, 0, 0, 0, 0, //6
        0, 0, 0, 0, 0, 0, //6
        0, 0, 0, 0, 0, 0, //6
        0, 0, 0, 0, 0, 0  //7
    ]
    megaBoss: Bidder;
    nextBidderIndex = 0;
    constructor(private readonly canvas: HTMLCanvasElement, private db: AngularfirestoreLiveMachineService, private db2: AngularfirestoreMJStoreService, post_id: string) {
        var now = Date.now();
        //console.log(now)
        this.context = this.canvas.getContext('2d');
        firebase.initializeApp(environment.mjFirebase);
        let admins = ["Ue80e5d6132db1dbd52ed0bd20d3530b1", "Ua6325a824d52d3950502e223cb9cd891"]
        db2.collection("linemessages", ref => ref.where('timestamp', '>=', now)
            .where('isFromUser', '==', true)
            .orderBy('timestamp', 'desc'))
            .valueChanges({ idField: 'comment_id' }).subscribe((commentsFromDb: any[]) => {
                //console.log(commentsFromDb)
                for (let index = 0; index < commentsFromDb.length; index++) {
                    let commentFromDb = commentsFromDb[index];

                    //====================================
                    let newbidder = this.bidders.find(a => {
                        return a.user.userId == commentFromDb.lineuser
                    })
                    db2.collection('lineusers').doc(commentFromDb.lineuser).valueChanges().subscribe(user => {
                        //console.log({ user, newbidder })
                        if (newbidder) {
                            newbidder.user = user;
                            if (newbidder.user.points === undefined) {
                                newbidder.user.points = 20;
                            }
                        }
                        else {
                            var seat = this.findAvailableSeat();
                            if (seat != -1) {
                                this.seats[seat] = 1;
                                newbidder = new Bidder(this, this.players[this.nextBidderIndex],
                                    this.atlases[this.nextBidderIndex],
                                    this.paths[this.nextBidderIndex],
                                    user, seat, db2);
                                this.nextBidderIndex++;
                                if (this.nextBidderIndex == this.players.length) {
                                    this.nextBidderIndex = 0;
                                }
                                let row = Math.floor(seat / this.colsCount);
                                let col = seat - (row * this.colsCount);
                                newbidder.x = col * this.seatWidth + (this.offsetX + this.seatWidth / 2)
                                newbidder.y = row * this.seatHeight + this.seatHeight
                                newbidder.xx = newbidder.x;
                                newbidder.yy = newbidder.y;

                                var offsetY = 520;
                                var crowdedWidth = this.seatWidth / 2;
                                var crowdedHeight = this.seatHeight / 2;
                                newbidder.xxx = col * crowdedWidth + (this.offsetX + crowdedWidth / 2)
                                newbidder.yyy = row * crowdedHeight + crowdedHeight + offsetY
                                this.bidders.push(newbidder);
                            }
                        }

                        var comment = this.comments.find(a => {
                            return a.comment_id == commentFromDb.comment_id
                        })
                        //console.log(now - commentFromDb.created_time)
                        if (!comment && commentFromDb.type == "text") {
                            this.comments.push(commentFromDb);
                            if (commentFromDb.message && commentFromDb.message.text.toLowerCase() == 'bodysurf') {
                                for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                    let bidder = this.bidders[chindex];
                                    if (bidder.user.userId != commentFromDb.lineuser) {
                                        bidder.x = bidder.xx
                                        bidder.y = bidder.yy
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'support' }, { merge: true }).then(() => {
                                            bidder.user.state = 'support'
                                        })
                                    }
                                }
                            }
                            if (this.game) {
                                if (commentFromDb.message.text == this.game && this.stock > 0) {
                                    var winner = this.winners.find(a => {
                                        return a.user.userId == newbidder.user.userId
                                    })
                                    if (!winner) {
                                        this.winners.push(newbidder)

                                        let linePush = firebase.functions().httpsCallable('linePush');
                                        let message = 'โอ้โห!! คุณพิมพ์ ' + this.game + ' ได้อย่างถูกต้องและว่องไว รอรับรางวัลเมื่อจบเกมนะคะ';
                                        linePush({
                                            messages: { type: 'text', text: message },
                                            to: newbidder.user.userId,
                                        }).then(function (result) {

                                        }).catch(function (error) {
                                            // Getting the Error details.
                                            var code = error.code;
                                            var message = error.message;
                                            var details = error.details;
                                            // ...
                                        });

                                        this.db2.doc('lineusers/' + newbidder.user.userId).set({ state: 'win' }, { merge: true }).then(() => {
                                            newbidder.user.state = 'win'
                                        })
                                        this.stock--;
                                    }
                                }
                            }
                            if (this.bidding && this.bidding.bidding) {
                                let currentTryPrice = parseInt(commentFromDb.message.text);
                                if (
                                    commentFromDb.message.text.indexOf('#') < 0 &&
                                    this.bidding.secs > 0
                                ) {
                                    //
                                    newbidder.bidding = true;
                                    if (currentTryPrice !== NaN &&
                                        currentTryPrice - this.bidding.currentPrice >= this.bidding.step) {
                                        this.bidding.currentWinner = newbidder;
                                        this.bidding.currentPrice = currentTryPrice;

                                        //=================
                                        let linePush = firebase.functions().httpsCallable('linePush');
                                        linePush({
                                            messages: { type: 'text', text: 'คุณขึ้นนำที่ราคา ' + currentTryPrice + ' บาทค่ะ' },
                                            to: newbidder.user.userId,
                                        }).then(function (result) {
                    
                                        }).catch(function (error) {
                                            // Getting the Error details.
                                            var code = error.code;
                                            var message = error.message;
                                            var details = error.details;
                                            // ...
                                        });

                                        for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                            const bidder = this.bidders[chindex];
                                            if(bidder.user.userId !== newbidder.user.userId && bidder.bidding){
                                                let linePush = firebase.functions().httpsCallable('linePush');
                                                let message = 'ราคาขึ้นไปที่ ' + currentTryPrice + ' แล้วค่ะ สามารถใส่ราคาเพิ่มครั้งละ ' + this.bidding.step + ' บาท กดปุ่มราคาด้านล่าง หรือพิมพ์ราคาที่ต้องการเข้ามาได้เลยค่ะ';
                                                linePush({
                                                    messages: {
                                                        text: message, type: 'text', quickReply: {
                                                            items: [
                                                                {
                                                                    "type": "action",
                                                                    "imageUrl": "https://morejungle.com/assets/images/shoppingcart.png",
                                                                    "action": {
                                                                        "type": "message",
                                                                        "label": "ใส่ราคา " + (currentTryPrice + this.bidding.step),
                                                                        "text": (currentTryPrice + this.bidding.step) + ''
                                                                    }
                                                                },
                                                                {
                                                                    "type": "action",
                                                                    "imageUrl": "https://morejungle.com/assets/images/shoppingcart.png",
                                                                    "action": {
                                                                        "type": "message",
                                                                        "label": "ใส่ราคา " + (currentTryPrice + this.bidding.step * 2),
                                                                        "text": (currentTryPrice + this.bidding.step * 2) + ''
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    to: bidder.user.userId,
                                                }).then(function (result) {
        
                                                }).catch(function (error) {
                                                    // Getting the Error details.
                                                    var code = error.code;
                                                    var message = error.message;
                                                    var details = error.details;
                                                    // ...
                                                });
                                            }
                                        }
                                        //==================

                                        this.db2.doc('lineusers/' + newbidder.user.userId).set({ state: 'win' }, { merge: true }).then(() => {
                                            newbidder.user.state = 'win'
                                            for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                                const bidder = this.bidders[chindex];
                                                if (bidder.user.userId !== newbidder.user.userId) {
                                                    this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'still' }, { merge: true }).then(() => {
                                                        bidder.user.state = 'still'
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                            //var commands = ['jump', 'moshpit', 'still', 'sleep', 'bodysurf', 'attack', 'Jump', 'Moshpit', 'Still', 'Sleep', 'Bodysurf', 'Attack'];
                            var reCommands = /(jump|moshpit|still|sleep|bodysurf|attack)/gi
                            let mCommand = reCommands.exec(commentFromDb.message.text)
                            if (mCommand) {
                                let command = mCommand[1].toLowerCase();
                                this.db2.doc('lineusers/' + newbidder.user.userId).set({ state: command }, { merge: true }).then(() => {
                                    newbidder.user.state = command
                                })
                            }
                            if (admins.indexOf(commentFromDb.lineuser) >= 0) {
                                //PAGE COMMENT
                                let re = /BOSS(\d+)/i
                                const m = re.exec(commentFromDb.message.text)
                                if (m) {
                                    var bossHP = parseInt(m[1]);
                                    if (bossHP < 500) {
                                        this.megaBoss = new Bidder(this, p26, a26, 'Boss1', {}, -1, db2);
                                        this.megaBoss.y = 1000
                                        this.megaBoss.yy = 1000
                                    } else {
                                        this.megaBoss = new Bidder(this, p25, a25, 'MegaBoss', {}, -1, db2);
                                        this.megaBoss.y = 450
                                        this.megaBoss.yy = 450
                                    }
                                    this.megaBoss.x = 1920 / 2
                                    this.megaBoss.xx = 1920 / 2

                                    this.megaBoss.HP = bossHP
                                    this.megaBoss.is_boss = true;
                                }
                                let re2 = /KILLBOSS/i
                                const m2 = re2.exec(commentFromDb.message.text)
                                if (m2) {
                                    this.megaBoss = undefined;
                                }
                                let re3 = /ALLMOSH/i
                                const m3 = re3.exec(commentFromDb.message.text)
                                if (m3) {
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'moshpit' }, { merge: true }).then(() => {
                                            bidder.user.state = 'moshpit'
                                        })
                                    }
                                }
                                let re4 = /ALLSTILL/i
                                const m4 = re4.exec(commentFromDb.message.text)
                                if (m4) {
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'still' }, { merge: true }).then(() => {
                                            bidder.user.state = 'still'
                                        })
                                    }
                                }
                                let re5 = /ALLSLEEP/i
                                const m5 = re5.exec(commentFromDb.message.text)
                                if (m5) {
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'sleep' }, { merge: true }).then(() => {
                                            bidder.user.state = 'sleep'
                                        })
                                    }
                                }
                                let re6 = /ALLJUMP/i
                                const m6 = re6.exec(commentFromDb.message.text)
                                if (m6) {
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'jump' }, { merge: true }).then(() => {
                                            bidder.user.state = 'jump'
                                        })
                                    }
                                }
                                let re7 = /ALL100/i
                                const m7 = re7.exec(commentFromDb.message.text)
                                if (m7) {
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        if (bidder.user.points < 100) {
                                            this.db2.doc('lineusers/' + bidder.user.userId).set({ points: 100 }, { merge: true }).then(() => {
                                                bidder.user.points = 100
                                            })
                                            bidder.user.points = 100
                                        }
                                    }
                                }
                                let re8 = /game\s(.*)\s(\d+)/i
                                const m8 = re8.exec(commentFromDb.message.text)
                                if (m8) {
                                    let game = m8[1];
                                    let stock = parseInt(m8[2]);
                                    this.game = game;
                                    this.stock = stock;
                                    this.winners = []
                                }

                                let re9 = /KILLGAME/i
                                const m9 = re9.exec(commentFromDb.message.text)
                                if (m9) {
                                    this.game = '';
                                    this.stock = 0;
                                    this.winners = []
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: '' }, { merge: true }).then(() => {
                                            bidder.user.state = ''
                                        })
                                    }
                                }

                                let re10 = /bid\s(.*)\s(\d+)\s(\d+)\s(\d+)\s(\d+)/i
                                const m10 = re10.exec(commentFromDb.message.text)
                                if (m10) {
                                    let name = m10[1];
                                    let currentPrice = parseInt(m10[2]);
                                    let step = parseInt(m10[3]);
                                    let normalPrice = parseInt(m10[4]);
                                    let secs = parseInt(m10[5]);
                                    this.bidding = {
                                        bidding: true,
                                        name: name,
                                        currentPrice: currentPrice,
                                        step: step,
                                        normalPrice: normalPrice,
                                        secs: secs,
                                        ended: false
                                    }
                                }

                                let re11 = /KILLBID/i
                                const m11 = re11.exec(commentFromDb.message.text)
                                if (m11) {
                                    this.bidding = { bidding: false };
                                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                                        const bidder = this.bidders[chindex];
                                        bidder.bidding = false;
                                        this.db2.doc('lineusers/' + bidder.user.userId).set({ state: 'still' }, { merge: true }).then(() => {
                                            bidder.user.state = 'still'
                                        })
                                    }
                                }
                            }
                        }
                    })
                    //====================================
                }
            })
        this.animate()
    }

    findAvailableSeat() {
        for (let index = this.seats.length - 1; index >= 0; index--) {
            const seat = this.seats[index];
            if (seat == 0) {
                return index
            }
        }
        return -1;
    }
    angle = 0;
    radiusX = 500;
    radiusY = 2000;
    centerX = 500;
    centerY = 500;
    currentFrame = 0;
    animate() {
        this.currentFrame++;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // for (let col = 0; col <= this.colsCount; col++) {
        //     this.context.moveTo(col * this.seatWidth + this.offsetX, 0);
        //     this.context.lineTo(col * this.seatWidth + this.offsetX, 1080);
        //     // set line color
        //     this.context.strokeStyle = '#ff0000';
        //     this.context.stroke();
        // }
        // for (let row = 0; row <= this.rowsCount; row++) {
        //     this.context.moveTo(0, row * this.seatHeight + this.offsetY);
        //     this.context.lineTo(1920, row * this.seatHeight + this.offsetY);
        //     // set line color
        //     this.context.strokeStyle = '#ff0000';
        //     this.context.stroke();
        // }
        this.context.font = '80px sukhumvit';
        this.context.textAlign = "left";
        this.context.fillStyle = "white";
        //this.context.fillText('ผู้เข้าแข่งขัน', this.offsetX, 2.5 * this.seatHeight)
        //this.context.fillText('ผู้ชนะ', this.offsetX, 0.5* this.seatHeight)
        if (this.game) {
            this.context.font = '80px sukhumvit';
            this.context.textAlign = "right";
            this.context.fillText('พิมพ์ ' + this.game + ' เข้ามาในไลน์ให้เร็วที่สุด', 6 * this.seatWidth, this.seatHeight)
            if (this.stock > 0) {
                this.context.fillText('เหลือ ' + this.stock + ' รางวัลเท่านั้น', 6 * this.seatWidth, 1.5 * this.seatHeight)
            } else {
                this.context.fillText('จบเกม เชิญรับรางวัลจ้ะ', 6 * this.seatWidth, 1.5 * this.seatHeight)
            }
            this.context.textAlign = "left";
            for (let index = this.winners.length - 1; index >= 0; index--) {
                let winner = this.winners[index];
                winner.yyyy = 2 * this.seatHeight;
                winner.xxxx = index * this.seatWidth + (this.offsetX + this.seatWidth / 2)
            }
        }
        if (this.bidding && this.bidding.bidding) {
            if (this.currentFrame % 8 == 0 && this.bidding.secs > 0) {
                this.bidding.secs--;
            }
            if (this.bidding.secs <= 0 && !this.bidding.ended) {
                this.bidding.ended = true;
                if (this.bidding.currentWinner) {
                    let linePush = firebase.functions().httpsCallable('linePush');
                    let message = 'ขอแสดงความยินดีด้วยค่ะ คุณชนะการประมูล ' + this.bidding.name + ' ในราคา ' + this.bidding.currentPrice + ' บาทค่ะ';
                    linePush({
                        messages: { type: 'text', text: message },
                        to: this.bidding.currentWinner.user.userId,
                    }).then(function (result) {

                    }).catch(function (error) {
                        // Getting the Error details.
                        var code = error.code;
                        var message = error.message;
                        var details = error.details;
                        // ...
                    });

                    for (let chindex = 0; chindex < this.bidders.length; chindex++) {
                        const bidder = this.bidders[chindex];
                        if(bidder.user.userId !== this.bidding.currentWinner.user.userId && bidder.bidding){
                            let linePush = firebase.functions().httpsCallable('linePush');
                            let message = 'ผู้ประมูลท่านอื่นชนะไปที่ราคา ' + this.bidding.currentPrice + ' บาทค่ะ ขอให้โชคดีในต้นถัดไปนะคะ';
                            linePush({
                                messages: { type: 'text', text: message },
                                to: bidder.user.userId,
                            }).then(function (result) {
        
                            }).catch(function (error) {
                                // Getting the Error details.
                                var code = error.code;
                                var message = error.message;
                                var details = error.details;
                                // ...
                            });
                        }
                    }
                }
            }
            this.context.font = '90px sukhumvit';
            this.context.textAlign = "right";
            this.context.fillText(this.bidding.secs <= 0 ? 'หมดเวลา' : '' + this.bidding.secs, 6 * this.seatWidth, 0.5 * this.seatHeight)
            this.context.font = '80px sukhumvit';
            this.context.fillText('ประมูล ' + this.bidding.name, 6 * this.seatWidth, this.seatHeight)
            this.context.fillText('ราคาขายปกติ ' + this.bidding.normalPrice + ' บาท', 6 * this.seatWidth, 1.5 * this.seatHeight)
            this.context.fillText('เพิ่มครั้งละ ' + this.bidding.step + ' บาทขึ้นไป', 6 * this.seatWidth, 2 * this.seatHeight)
            this.context.fillStyle = "#ffffff";
            this.context.font = '120px sukhumvit';
            // this.context.fillText('ราคาประมูลปัจจุบัน ' + this.bidding.currentPrice + ' บาท', 6 * this.seatWidth, 2.5 * this.seatHeight)


            this.context.textAlign = "left";
            this.context.font = '220px sukhumvit';
            this.context.fillText(this.bidding.currentPrice + ' บาท', this.offsetX, this.seatHeight)
            if (this.bidding.currentWinner) {
                this.context.font = '80px sukhumvit';
                this.context.fillText('โดยคุณ ' + this.bidding.currentWinner.user.displayName, this.offsetX, 2 * this.seatHeight)
                this.bidding.currentWinner.yyyy = 2 * this.seatHeight;
                this.bidding.currentWinner.xxxx = this.seatWidth + (this.offsetX + this.seatWidth / 2)
            }
        }

        for (let index = this.bidders.length - 1; index >= 0; index--) {
            const bidder = this.bidders[index];
            bidder.draw();
        }
        if (this.megaBoss) {
            this.megaBoss.draw();
        }
        //this.bidders[20].draw()
        window.requestAnimationFrame(() => this.animate());
    }
}