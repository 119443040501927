import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { SseService } from '../services/sse.service';

@Component({
  selector: 'app-livecomments',
  templateUrl: './livecomments.component.html',
  styleUrls: ['./livecomments.component.scss']
})
export class LivecommentsComponent implements OnInit {
  public page = {
    current_commentator: "",
    current_commentator_id: "",
    current_comment: ""
  }
  constructor(private route: ActivatedRoute, firestore: AngularFirestore, private sseService: SseService) {
    var token = "EAAD1ZBDOeMj4BAMueqybk0X0RqfefbZAkCDNII6Pe72FGiwU17YxhRo4iFGLHtnlHK0bru2ZCSbiLZC0oMZA6tbZBqIzG0w2aTFSXRW9woEI6sdPSJGyGXQaAUk1JpsTP63eZAjmSBOjL0ngDP7cKgBjXnslLzWY4q2qaqr1jMNywZDZD";
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.sseService
        .getServerSentEvent("https://streaming-graph.facebook.com/" + id + 
        "/live_comments?access_token=" + token + 
        "&comment_rate=ten_per_second&fields=from{name,id},message,created_time")
        .subscribe(sse => {
          var comment = JSON.parse(sse.data);
          this.page.current_comment = comment.message;
          this.page.current_commentator_id = comment.from.id;
          this.page.current_commentator = comment.from.name;
          // var that = this;
          // stream.forEach(function (comment) {
          //   //NONE, LIKE, LOVE, WOW, HAHA, SAD, ANGRY, THANKFUL, and PRIDE.
          //  console.log(comment)
          // });

          //console.log(this.reactions)
        });
    });
  }

  ngOnInit() {
  }

}
